<template>
	<section>
		<b-sidebar
			type="is-blue"
			:fullheight="true"
			:overlay="isMobile"
			v-model="isSidebarOpen"
			:on-cancel="closeSidebar"
			class="sidebar-home"
			:can-cancel="isMobile ? ['escape', 'outside'] : null"
		>
			<div class="top-bar ml-3">
				<b-button v-show="isMobile" @click="closeSidebar" type="is-ghost" class="has-text-white">
					<b-icon class="close-icon" pack="st" icon="close" size="is-large"></b-icon>
				</b-button>
				<img :src="require('@/assets/icons/logo.svg')" alt="S" />
			</div>

			<div>
				<b-menu>
					<b-menu-list>
						<!-- <b-menu-item 
							class="has-text-white is-title-2-m" 
							:active="$route.path == '/backoffice'"
							:label="t.general" @click="$router.push({name:'BackOffice'})">
						</b-menu-item> -->
						<b-menu-item 
							class="has-text-white is-title-2-m" 
							:active="$route.path == '/backoffice/mediators'"
							:label="t.mediators" @click="goTo('BackOfficeMediators')">
						</b-menu-item>
							<b-menu-item 
							class="has-text-white is-title-2-m"
							:active="$route.path == '/backoffice/users'"
							:label="t.users" @click="goTo('BackOfficeUsers')">
						</b-menu-item>
						<!-- <b-menu-item class="has-text-white is-title-2-m" :label="t.settings"></b-menu-item> -->
						<b-menu-item class="has-text-white is-title-2-m" :label="t.logout" @click="execLogout"></b-menu-item>
					</b-menu-list>
				</b-menu>
			</div>
		</b-sidebar>
	</section>
</template>

<script>
	export default {
		name: "Sidebar",
		data() {
			return {
				t: this.$t("components.BackOffice.Sidebar")
			};
		},
		created(){
			if(this.$route.path == '/backoffice') this.closeSidebar();
		},
		methods: {
			closeSidebar() {
				this.setSidebarOpen(false);
			},
			execLogout() {
				this.logout();
			},
			goTo(name) {
				this.$router.push({name});
				this.closeSidebar();
			}
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';

	
	.top-bar {
		display: flex;
		margin-bottom: $space;
		img {
			margin-left: $space/2;
		}
	}
	.menu-list a.is-active {
		background-color: $white;
		color: #1981fc;
	}
</style>