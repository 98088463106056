<template>
    <div
		class="first-div has-background-gray-light"
		:class="{ 'has-navbar-fixed-top': isMobile,'has-sidebar-fixed': !isMobile}"
	>
		<Navbar />
		<Sidebar />
		<div class="columns is-mobile">
			<div class="column is-offset-1 is-10 level is-mobile mt-16">
				<h4 class="is-headline-m-m is-big-title-m-d level-left">{{t.panel}}</h4>
			</div>
		</div>
        <div class="columns is-mobile">
			<div class="column is-offset-1 is-10 level is-mobile">
                <div class="level-left">
                    <a class="is-headline-m-m is-title-2-m level-item" :class="{ 'is-active': active=='active' }" @click="active='active';getData(0)">{{t.active}}</a>
				    <a class="is-headline-m-m is-title-2-m level-item" :class="{ 'is-active': active=='registered' }" @click="active='registered';getData(0)">{{t.registered}}</a>
                </div>
			</div>
		</div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-offset-1 is-3-desktop is-10-touch">
				<div class="st-card has-background-white has-text-black p-4">
					<div class="is-mobile p-4">
						<div class="level is-mobile mb-8">
							<h1 class="level-left-desktop is-title-2-m-d">{{t.order}}</h1>
						</div>
						<div class="select is-small">
							<select @change="getData(0)" v-model="selectedTime">
								<option value="desc" selected>{{t.order_desc}}</option>
								<option value="asc">{{t.order_asc}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
            <div class="column is-7-desktop is-10-touch" :class="{'is-offset-1': isMobile}">
                <div class="st-card has-background-white has-text-black p-8">
                    <h4 class="is-title-2-m-m is-title-2-m-d level-left mb-4">{{t.panel}}</h4>
                    <b-table
                        :data="mediators"
                        ref="table"
                        detailed
                        detail-key="_id"
                        :show-detail-icon="false"
                        class="users mb-8"
                        :bordered="false"
                        >

                        <b-table-column  :label="t.table.name" v-slot="props">
                            <template>
                                {{ props.row.name }} {{ props.row.surname }}
                            </template>
                        </b-table-column>

                        <b-table-column :label="t.table.business_name" v-slot="props">
                                {{ props.row.business_name }}
                        </b-table-column>

                        <b-table-column :label="t.table.createdAt" v-slot="props">
                            <span>
                                {{ moment(props.row.createdAt).fromNow() }}
                            </span>
                        </b-table-column>
                        
                        <b-table-column label="" v-slot="props">
                            <a @click="props.toggleDetails(props.row); toggled(props.row._id)">
                                <i :class="toggle[props.row._id] ? 'st st-arrow-down': 'st st-arrow-right'" class="has-text-blue"></i>
                            </a>
                        </b-table-column>

                        <template #detail="props">
                            <article class="media">
                                <div class="media-content">
                                    <div class="content">
                                        <p class="mb-0"> {{ $t('fields.email')}}</p>
                                        <p class=" ml-4"><small>{{props.row.email}}</small></p>
                                        <p class="mb-0"> {{ $t('fields.phone')}}</p>
                                        <p class=" ml-4"><small>{{props.row.phone}}</small></p>
                                        <p class="mb-0"> {{ $t('fields.asf_number')}}</p>
                                        <p class="ml-4"><small>{{props.row.asf_number}}</small></p>
                                        <p class="mb-0"> {{ $t('fields.postal_code')}}</p>
                                        <p class="ml-4"><small>{{props.row.postal_code.number}}-{{props.row.postal_code.ext}}</small></p>
                                       
                                        <button v-if="!props.row.isActive" class="approve is-medium" @click="acceptMediator(props.row._id)"><i class="st st-tick has-text-green"></i></button>
                                        <button class="approve is-medium" @click="deleteMediator(props.row._id)"><i class="st st-close has-text-red"></i></button>

                                    </div>
                                </div>
                            </article>
                        </template>
                    </b-table>
                    <nav class="pagination is-right is-rounded" role="navigation" aria-label="pagination" v-if="pagination > 1">
                        <button class="pagination-previous" :disabled="page==0" @click="getData(page - 1)"><i class="st st-arrow-left has-text-blue"></i></button>
                        <button class="pagination-next" :disabled="page==pagination-1" @click="getData(page + 1)"><i class="st st-arrow-right has-text-blue"></i></button>
                    </nav>
                </div>
            </div>    
        </div>    
	</div>
</template>
<script>
import Sidebar from "@/components/BackOffice/Home/Sidebar";
import Navbar from "@/components/BackOffice/Home/Navbar";

import { getUsers, approveMediator, deleteUser } from '@/api/users';

export default {
    name:'Mediators',
    components: { Sidebar, Navbar },
    data(){
        return {
            t: this.$t("views.BackOffice.Mediators"),
            active: "active",
            page: 0,
            pagination: 0,
            selectedTime: 'desc',
            toggle: {},
            mediators: []
        }
    },
    created(){
        this.getData(0);
    },
    methods: {
        getData(start){
            this.page = start;

            let query = {
                user_type: 'mediator',
                order:this.selectedTime, 
                start: (this.page*10),
            };

            if(this.active == 'active') query.isActive = true;
            else query.isActive = false;

            getUsers(query)
                .then(response => {
                    this.mediators = response.data.data;
                    this.pagination = Math.ceil(response.data.count/10);
                })
                .catch(error => {
                });
        },
        toggled(id){
            if(this.toggle[id]) 
                this.toggle[id] = false;
            else this.toggle[id] = true;
        },
        acceptMediator(id){
            approveMediator(id)
                .then(response => {
                    this.$buefy.toast.open({
                        message: this.t.approved,
                        position: "is-bottom",
                        type: "is-success",
                    });
                    this.getData(0);
                })
                .catch(error => {
                    this.$buefy.toast.open({
                        message: this.t.approved_error,
                        position: "is-bottom",
                        type: "is-error",
                    });
                });
        },
        deleteMediator(id){
            this.$buefy.dialog.confirm({
                title: this.t.delete_title,
                message: this.t.are_you_sure,
                cancelText: this.t.cancel,
                confirmText: this.t.confirm,
                type: 'is-danger',
                onConfirm: () => {
                    deleteUser(id)
                        .then(response => {
                            this.$buefy.toast.open({
                                message: this.t.deleted,
                                position: "is-bottom",
                                type: "is-success",
                            });
                            this.getData(0);
                        })
                        .catch(error => {
                            this.$buefy.toast.open({
                                message: this.t.deleted_error,
                                position: "is-bottom",
                                type: "is-error",
                            });
                        });
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.first-div {
		> .columns:nth-of-type(1) {
			padding-top: $space/2;
			padding-bottom: $space * 1.5;
		}
		@include desktop() {
			background-color: $gray-light;
		}
		padding-bottom: 10vh;
		min-height: 100vh;

		div:nth-of-type(1) {
			img {
				max-width: 4rem;
				border: 2px solid $white;
				border-radius: 99999999999999px;
			}
		}
	}

	.select {
		width: 100%;

		select {
			width: 100%;
			border: none;
			border-bottom: solid $black;
		}
	}

	a,
	a:hover {
		color: $gray;
	}

	.is-active,
	.is-active:hover {
		color: $black;
	}
    .approve {
        border: none;
        background-color: transparent;
        /* float: right; */
    }
</style>